import React, { useState } from "react"
import { loadStripe } from "@stripe/stripe-js"
import FastAPIClient from "../../../../auth/fast_api_auth";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import {navigate} from "gatsby";


const buttonStyles = {
  fontSize: '13px',
  textAlign: 'center',
  color: '#000',
  padding: '12px 60px',
  boxShadow: '2px 5px 10px rgba(0,0,0,.1)',
  backgroundColor: 'rgb(255, 178, 56)',
  borderRadius: '6px',
  letterSpacing: '1.5px',
}

const buttonDisabledStyles = {
  opacity: '0.5',
  cursor: 'not-allowed',
}

const Checkout = ({ school, course }) => {
  const [loading, setLoading] = useState(false)
  const [coupon, setCoupon] = useState('');
  const [error, setError] = useState(false);
  const schoolExternalId = school.external_id;
  let client = new FastAPIClient(schoolExternalId);

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(false);
    setLoading(true);
    console.log(coupon)
    client.couponCalc(coupon, parseInt(course?.price_info?.id))
        .then((data) => {
          toast.success('Coupon applied! Discount will appear on next page')
        }).catch((err => {
          console.log(err)
          setCoupon(null)
          toast.error("Invalid coupon");
    }))
        .finally(() => {setLoading(false)})
  }

  const redirectToCheckout = async event => {
    event.preventDefault()
    // stripe checkout redirects will fail from the iframe preview
    if (school.isPreview) {
      alert('checkout is not permitted in preview mode');
      return;
    }
    setLoading(true)
    if (typeof window !== 'undefined') {
      const utmSource = localStorage.getItem('utmSource');
      const utmMedium = localStorage.getItem('utmMedium');
      const utmCampaign = localStorage.getItem('utmCampaign');
      window.gtag("event", 'begin_checkout', {
        "coupon": `${utmSource}-${utmMedium}-${utmCampaign}`,
        items: [
          {
            "name": course?.price_info?.name,
            "coupon": `${utmSource}-${utmMedium}-${utmCampaign}`
          }
        ]
      });
    }
    try {
      let checkoutSession;
      // fetch stripe or paddle checkout session
      checkoutSession = await client.createEnrollment(course, coupon)
      console.log(checkoutSession);
      if(checkoutSession?.payment_provider_type === "stripe"){
        // Get identifiers required for stripe js client
        const schoolStripeAccountID = await client.getSchoolStripeID();
        const platformStripeId = await client.getPlatformStripePK();
        // Initialise stripe client
        const stripe = await loadStripe(platformStripeId, {stripeAccount: schoolStripeAccountID});
        // now call stripe checkout session with this session ID
        // once the payment goes through use the refresh token endpoint to get a new JWT
        const { error } = stripe.redirectToCheckout({
          sessionId: checkoutSession.session_id
        }).then(function(result){
          console.log(result)
          if(result?.error?.message) {
            toast.error("There was an error processing your payment, please try again. If the problem continues" +
                "please contact the school owner");
          }
        })
      }else if(checkoutSession?.payment_provider_type === "paddle"){
        // Get identifiers required for paddle js client
        const schoolPaddleVendorID = await client.getPaddleVendorID();
        // Initialise paddle client
        Paddle.Setup({vendor: schoolPaddleVendorID})
        // now call paddle checkout session with this paddle_checkout_url
        Paddle.Checkout.open({
          override: checkoutSession.paddle_checkout_url
        });
      }else{
        setLoading(false);
        throw Error("Unknown payment_provider="+checkoutSession?.payment_provider)
      }
    } catch(err) {
      toast.error("There was an error on the server, please try again. If the problem continues" +
          "please contact the school owner");
      setLoading(false);
      throw err
    }
    setLoading(false);
  }

  return (
    <div>
      <button
        className={`px-3 py-2 text-center btn-lg`}
        disabled={loading}
        style={
          loading ? { ...buttonStyles, ...buttonDisabledStyles } : buttonStyles
        }
        onClick={redirectToCheckout}
      >
        Purchase Course
      </button>
      <form className="px-8 py-10 bg-white rounded-lg shadow-lg md:px-10 md:py-12" onSubmit={handleSubmit}>
        <div className="input-fields space-y-4">
          <div className="input-field">
            <label className="block mb-2 font-semibold text-gray-700" htmlFor="email">
              Coupon Code
            </label>
            <input
                className="px-3 py-2 font-light bg-gray-100 border border-gray-300 rounded"
                autoFocus
                id="coupon"
                type="text"
                value={coupon}
                disabled={loading}
                onChange={e => setCoupon(e.target.value)}
            />
          </div>
        </div>
        <button
            disabled={loading}
            type="submit"
            className={`text-center btn text-white bg-blue-500`}
            sx={{
              variant: `buttons.primary`,
            }}>
          Apply Coupon
        </button>
      </form>
    </div>
  )
}

export default Checkout